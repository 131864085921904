// import axios from "../utils/axios";
import axios from "axios";

export function signIn(credentials) {
  console.log("Signin");
  return new Promise((resolve, reject) => {
    console.log("Sending Request", JSON.stringify(axios));
    axios
      .post("/shop/dashboardLogin", credentials)
      .then((response) => {
        if (response.status === 200) {
          console.log("login response", response);
          resolve(response.data);
        }
        reject(response);
      })
      .catch((error) => {
        console.log("login error", error);
        reject(error);
      });
  });
}

export function signUp(credentials) {
  return new Promise((resolve, reject) => {
    axios
      .post("/api/auth/sign-up", credentials)
      .then((response) => {
        if (response.status === 200) {
          resolve(response.data);
        }
        reject(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function resetPassword(credentials) {
  return new Promise((resolve, reject) => {
    axios
      .post("/api/auth/reset-password", credentials)
      .then((response) => {
        if (response.status === 200) {
          resolve(response.data);
        }
        reject(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}
