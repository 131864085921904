import {
  Divider as MuiDivider,
  Grid,
  Table,
  Backdrop,
  TableBody,
  TableCell,
  TableContainer,
  TableHead as MuiTableHead,
  TableRow,
  Typography as MuiTypography,
  Button,
  TextField,
  InputBase,
  FormControl,
  CircularProgress,
  Dialog,
  DialogActions,
  makeStyles,
  DialogContent,
  DialogTitle,
  Tooltip,
} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import { spacing } from "@material-ui/system";
import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import styled, { withTheme } from "styled-components/macro";
import firebase from "../../../config";
import Actions from "./Actions";
import EditIcon from "@material-ui/icons/Edit";
import IconButton from "@material-ui/core/IconButton";
import DeleteOutline from "@material-ui/icons/DeleteOutline";
import { Switch } from "@material-ui/core";
import { PowerSettingsNew as LogoutIcon } from "@material-ui/icons";
import { signOut } from "../../../redux/actions/authActions";

import axios from "axios";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";

const TableHead = withStyles((theme) => ({
  root: {
    backgroundColor: "#233044",
  },
}))(MuiTableHead);
const TableHeaderCell = withStyles((theme) => ({
  root: {
    color: "white",
  },
}))(TableCell);
const BootstrapInput = styled(InputBase)(({ theme }) => ({
  "& .MuiInputBase-input": {
    borderTopRightRadius: 0,
    borderBottomRightRadius: 0,
    position: "relative",
    backgroundColor: theme.palette.background.paper,
    border: "1px solid #ced4da",
    fontSize: 16,
    padding: "10px 26px 10px 12px",
    transition: theme.transitions.create(["border-color", "box-shadow"]),
  },
}));
const useStyles = makeStyles((theme) => ({
  backdrop: { zIndex: theme.zIndex.drawer + 1, color: "#fff" },
}));

const Divider = styled(MuiDivider)(spacing);

const Typography = styled(MuiTypography)(spacing);

function Logout({ theme }) {
  const history = useHistory();
  const dispatch = useDispatch();

  useSelector((data) => {
    // console.log("data", );
  });
  const doLogout = async () => {
    await dispatch(signOut());
    history.push("/auth/sign-in");
  };
  return (
    <React.Fragment>
      <Helmet title="Users" />
      <Grid justify="space-between" container spacing={6}>
        <Grid item></Grid>
        <Grid item onClick={() => doLogout()}>
          <LogoutIcon color="#233044" />
        </Grid>
      </Grid>
    </React.Fragment>
  );
}

export default withTheme(Logout);
