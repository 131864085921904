import {
  Backdrop,
  Button,
  Card as MuiCard,
  CardContent,
  CircularProgress,
  FormControl,
  Grid,
  InputBase,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead as MuiTableHead,
  TableRow,
  Typography as MuiTypography,
} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import { spacing } from "@material-ui/system";
import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import styled from "styled-components/macro";
import firebase from "../../../config";
import Logout from "../Analytics/Logout";

const Typography = styled(MuiTypography)(spacing);
const Card = styled(MuiCard)(spacing);
// const Divider = styled(MuiDivider)(spacing);
const TableWrapper = styled.div`
  overflow-y: auto;
  max-width: calc(100vw - ${(props) => props.theme.spacing(12)}px);
`;

const BootstrapInput = styled(InputBase)(({ theme }) => ({
  "& .MuiInputBase-input": {
    borderTopRightRadius: 0,
    borderBottomRightRadius: 0,
    position: "relative",
    backgroundColor: theme.palette.background.paper,
    border: "1px solid #ced4da",
    fontSize: 16,
    padding: "10px 26px 10px 12px",
    transition: theme.transitions.create(["border-color", "box-shadow"]),
  },
}));
const useStyles = makeStyles((theme) => ({
  backdrop: { zIndex: theme.zIndex.drawer + 1, color: "#fff" },
}));
const TableHead = withStyles((theme) => ({
  root: {
    backgroundColor: "#233044",
  },
}))(MuiTableHead);
const TableHeaderCell = withStyles((theme) => ({
  root: {
    color: "white",
  },
}))(TableCell);
function Services() {
  const [services, setServices] = useState(null);
  const [FilteredServices, setFilteredServices] = useState([]);
  const [Input, setInput] = useState("");
  const [open, setOpen] = React.useState(false);
  const classes = useStyles();
  useEffect(() => {
    fetchServices();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const fetchServices = async () => {
    setOpen(true);
    firebase
      .firestore()
      .collection("services")
      .get()
      .then(async (service) => {
        // let count = 0;
        // let counts = 0;
        if (!service.empty) {
          let data = [];
          if (!service.empty) {
            let l = service.docs.length;
            service.docs.map(async (x, i) => {
              // counts++;
              let t = x.data();
              let uid = t.uid;
              let artistName;
              let d = await firebase
                .firestore()
                .collection("users")
                .doc(uid)
                .get()
                .then((user) => {
                  if (user.exists) {
                    artistName = user.data().name;
                  }
                  x.data().services.map((s) => {
                    // count++;
                    let temp = s;
                    temp.uid = uid;
                    temp.artistName = artistName;
                    data.push(temp);
                    //  eslint-disable-next-line react-hooks/exhaustive-deps
                    return 0;
                  });
                  return data;
                });
              if (i + 1 === l) {
                setOpen(false);
                setServices(d);
                setFilteredServices(d);
              }
              return;
              // eslint-disable-next-line react-hooks/exhaustive-deps
            });
          }
        }
      })
      .catch((err) => {
        console.log("error", err);
      });
  };
  const searchText = (search) => {
    setInput(search);
    // console.log("search", search);
    const str2 = search.toLowerCase();

    // console.log("str2", str2);
    // const result = services.filter((i) => i.artistName.includes(str2));
    const result = services.filter((i) =>
      i.artistName.toLowerCase().includes(str2)
    );

    // console.log("result123", result);
    setFilteredServices(result);
  };
  const clearAll = () => {
    fetchServices();
    setInput("");
  };
  return (
    <React.Fragment>
      <Helmet title="Transactions" />
      <Backdrop className={classes.backdrop} open={open}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <Grid justify="space-between" container spacing={6}>
        <Grid item></Grid>
        <Logout />
      </Grid>
      <Card mb={6} mt={5}>
        <CardContent>
          <Typography variant="h6">Services</Typography>
        </CardContent>
      </Card>

      <Grid item style={{ marginBottom: 20 }}>
        <FormControl sx={{ m: 1 }} variant="standard">
          <BootstrapInput
            id="demo-customized-textbox"
            placeholder={`Search By Artist Name`}
            onChange={(e) => searchText(e.target.value)}
            value={Input}
            // inputProps={{ style: { textTransform: "capitalize" } }}
            autoComplete="off"
          />
        </FormControl>
        <Button
          style={{
            background: "#ba000d",
            color: "#fff",
            padding: "8px 20px 8px 20px",
            borderTopLeftRadius: 0,
            borderBottomLeftRadius: 0,
          }}
          variant="contained"
          onClick={() => {
            clearAll();
          }}
        >
          Clear
        </Button>
      </Grid>
      <TableContainer>
        <TableWrapper>
          <Table aria-label="spanning table">
            <TableHead>
              <TableRow>
                <TableHeaderCell align="center">Service Name</TableHeaderCell>
                <TableHeaderCell align="center">Price</TableHeaderCell>
                <TableHeaderCell align="center">Artist Name</TableHeaderCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {FilteredServices &&
                FilteredServices?.map((row, index) => (
                  <TableRow key={index}>
                    <TableCell align="center">{row.servicetype}</TableCell>
                    <TableCell align="center">${row.price}</TableCell>
                    <TableCell align="center">{row.artistName}</TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableWrapper>
      </TableContainer>
    </React.Fragment>
  );
}

export default Services;
