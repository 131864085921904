import * as types from "../../constants";
const INITIAL_STATE = {
  isLogin: false,
  user: null,
};
export default function reducer(state = INITIAL_STATE, actions) {
  switch (actions.type) {
    case types.AUTH_SIGN_IN_SUCCESS:
      return {
        ...state,
        isLogin: true,
        user: actions.payload,
      };

    case types.AUTH_SIGN_OUT:
      return {
        ...state,
        isLogin: false,
        user: undefined,
      };

    default:
      return state;
  }
}
