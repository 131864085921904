import {
  Backdrop,
  Button,
  Card as MuiCard,
  CardContent,
  CircularProgress,
  FormControl,
  Grid,
  InputBase,
  makeStyles,
  Typography as MuiTypography,
} from "@material-ui/core";
import { spacing } from "@material-ui/system";
import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import styled from "styled-components/macro";
import firebase from "../../../config";
import Logout from "../Analytics/Logout";

const Typography = styled(MuiTypography)(spacing);
const Card = styled(MuiCard)(spacing);
const BootstrapInput = styled(InputBase)(({ theme }) => ({
  "& .MuiInputBase-input": {
    borderTopRightRadius: 0,
    borderBottomRightRadius: 0,
    position: "relative",
    backgroundColor: theme.palette.background.paper,
    border: "1px solid #ced4da",
    fontSize: 16,
    padding: "10px 26px 10px 12px",
    transition: theme.transitions.create(["border-color", "box-shadow"]),
  },
}));
const useStyles = makeStyles((theme) => ({
  backdrop: { zIndex: theme.zIndex.drawer + 1, color: "#fff" },
}));

function Venues() {
  const [venues, setVenues] = useState(null);
  const [FilteredVenues, setFilteredVenues] = useState([]);
  const [Input, setInput] = useState("");
  const [open, setOpen] = React.useState(false);
  const classes = useStyles();

  useEffect(() => {
    fetchVenues();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const fetchVenues = () => {
    setOpen(true);
    firebase
      .firestore()
      .collection("venue")
      .get()
      .then((transacs) => {
        if (!transacs.empty) {
          let data = [];
          let uids = [];
          transacs.docs.forEach(async (x) => {
            let uid = x.data().owner;
            let ind = uids.findIndex((x) => x === uid);
            if (ind === -1) {
              uids.push(uid);
            }
            let temp = x.data();
            data.push(temp);
          });
          // uids.map((x) => {
          //   if (x != undefined) {
          //     firebase
          //       .firestore()
          //       .collection("users")
          //       .doc(x)
          //       .get()
          //       .then((u) => {
          //         let name;
          //         if (u.exists) {
          //           name = u.data().name;
          //         }
          //         let ind = data.findIndex((y) => y.owner == x);
          //         if (ind != -1) {
          //           data[ind]["ownerName"] = name;
          //         }
          //       });
          //   }
          // });
          setOpen(false);
          setVenues(data);
          setFilteredVenues(data);
          // console.log("uids", uids);
        }
      })
      .catch((err) => {
        console.log("error", err);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  };
  const searchText = (search) => {
    setInput(search);
    // console.log("search", search);
    const str2 = search.toLowerCase();

    // console.log("FilteredVenues", FilteredVenues);
    const result = venues.filter((i) => i.name.toLowerCase().includes(str2));
    // console.log("result123", result.length);
    setFilteredVenues(result);
  };
  const clearAll = () => {
    fetchVenues();
    setInput("");
  };
  // console.log("venue", venues);
  return (
    <React.Fragment>
      <Helmet title="Venues" />
      <Backdrop className={classes.backdrop} open={open}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <Grid justify="space-between" container spacing={6}>
        <Grid item></Grid>
        <Logout />
      </Grid>
      <Card mb={6} mt={5}>
        <CardContent>
          <Typography variant="h6">Venues</Typography>
        </CardContent>
        <Grid item style={{ marginBottom: 20, marginLeft: 5 }}>
          <FormControl sx={{ m: 1 }} variant="standard">
            <BootstrapInput
              id="demo-customized-textbox"
              placeholder={`Search By Venue Name`}
              onChange={(e) => searchText(e.target.value)}
              value={Input}
              // inputProps={{ style: { textTransform: "capitalize" } }}
              autoComplete="off"
            />
          </FormControl>
          <Button
            style={{
              background: "#ba000d",
              color: "#fff",
              padding: "8px 20px 8px 20px",
              borderTopLeftRadius: 0,
              borderBottomLeftRadius: 0,
            }}
            variant="contained"
            onClick={() => {
              clearAll();
            }}
          >
            Clear
          </Button>
        </Grid>
      </Card>
      {FilteredVenues &&
        FilteredVenues.map((item, index) => {
          return (
            <Card mb={5} key={index}>
              <CardContent>
                <div style={{ display: "flex" }}>
                  <Grid item xs={6} md={3}>
                    <Typography
                      style={{ fontWeight: "800" }}
                      variant="body2"
                      gutterBottom
                      mr={2}
                    >
                      Venue Name
                    </Typography>
                  </Grid>
                  <Typography variant="body2" gutterBottom mr={2}>
                    {item.name}
                  </Typography>
                </div>
                <div style={{ display: "flex" }}>
                  <Grid item xs={6} md={3}>
                    <Typography
                      style={{ fontWeight: "800" }}
                      variant="body2"
                      gutterBottom
                      mr={2}
                    >
                      Price
                    </Typography>
                  </Grid>
                  <Typography variant="body2" gutterBottom mr={2}>
                    {item.price}
                  </Typography>
                </div>
                <div style={{ display: "flex" }}>
                  <Grid item xs={6} md={3}>
                    <Typography
                      style={{ fontWeight: "800" }}
                      variant="body2"
                      gutterBottom
                      mr={2}
                    >
                      Description
                    </Typography>
                  </Grid>
                  <Typography variant="body2" gutterBottom mr={2}>
                    {item.desc}
                  </Typography>
                </div>

                <div style={{ display: "flex" }}>
                  <Grid item xs={6} md={3}>
                    <Typography
                      style={{ fontWeight: "800" }}
                      variant="body2"
                      gutterBottom
                      mr={2}
                    >
                      State
                    </Typography>
                  </Grid>
                  <Typography variant="body2" gutterBottom mr={2}>
                    {item.state}
                  </Typography>
                </div>
                <div style={{ display: "flex" }}>
                  <Grid item xs={6} md={3}>
                    <Typography
                      style={{ fontWeight: "800" }}
                      variant="body2"
                      gutterBottom
                      mr={2}
                    >
                      Town
                    </Typography>
                  </Grid>
                  <Typography variant="body2" gutterBottom mr={2}>
                    {item.town}
                  </Typography>
                </div>
                <div style={{ display: "flex" }}>
                  <Grid item xs={6} md={3}>
                    <Typography
                      style={{ fontWeight: "800" }}
                      variant="body2"
                      gutterBottom
                      mr={2}
                    >
                      Address
                    </Typography>
                  </Grid>
                  <Typography variant="body2" gutterBottom mr={2}>
                    {item.address}
                  </Typography>
                </div>
              </CardContent>
            </Card>
          );
        })}
    </React.Fragment>
  );
}

export default Venues;
