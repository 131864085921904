import firebase from "firebase";
var firebaseConfig = {
  apiKey: "AIzaSyA7K4JlMipx59ySdp9tlin8renTHDiK2y4",
  authDomain: "partyliveapp.firebaseapp.com",
  databaseURL: "https://partyliveapp-default-rtdb.firebaseio.com",
  projectId: "partyliveapp",
  storageBucket: "partyliveapp.appspot.com",
  messagingSenderId: "62375163819",
  appId: "1:62375163819:web:10520d35579ece749784ee",
  measurementId: "G-BCT549J62S",
};
// Initialize Firebase

var fb = firebase.initializeApp(firebaseConfig);
export default fb;
